import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import {FormattedMessage, FormattedDate, FormattedTime} from 'react-intl';

import { LoadingSpinner, GetUser, ActiveClaimAcceptDlg } from "..";
import { post } from "react-restfully";

import {
    useQuery,
    gql
} from "@apollo/client";


function ActiveClaim(props) {
    const claim = props.claim;
    const onCounter = props.onCounter;
    const [loading, setLoading] = useState(false);
    const [acceptTosChecked, setAcceptTosChecked] = useState(false);
    const navigate = useNavigate();
    
    const toggleTosChecked = (evt) => {
        setAcceptTosChecked(evt.target.checked);
    }

    // accept offer  dialog
    const [showAcceptDialog, setShowAcceptDialog] = useState(false);
    const [acceptValidated, setAcceptValidated] = useState(false);
    const [submitAcceptStatus, setSubmitAcceptStatus] = useState("");
    const [submitAcceptStatusState, setSubmitAcceptStatusState] = useState("danger");
    const showAcceptOffer = (evt) => {
        handleAcceptShow();
    }
    const handleAcceptShow = () => {
        setSubmitAcceptStatus(false);
        setAcceptValidated(false);
        setAcceptTosChecked(false);
        setShowAcceptDialog(true);
    }
    const handleAcceptClose = () => {
        setShowAcceptDialog(false);
    }
    const handleAcceptSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        console.log("ACCEPT OFFER")
        const form = event.currentTarget;
        let valid = form.checkValidity();
        
        setAcceptValidated(true);

        if (!acceptTosChecked) return;
        
        if (valid) { 
            let url = "/api/secure/approve-accept";
            let body =  {
                claim: claim,
                acceptTos:  acceptTosChecked,
                amount: claim.offer.amount
            };
            
            setSubmitAcceptStatus(false);
            setLoading(true);
            post(url, {
                payload: body,
                headers: 
                    {
                        "Authorization": "Bearer "+GetUser().jwttoken,
                        "Content-Type": "application/json"
                    }
            }).then(data => {
                if (data.status===0) {
                    claim.status="Accept Pending";
                    claim.acceptNeedsApproval=false;
                    claim.counterNeedsApproval=false;
                    claim.offer.amount=data.amount;
                    claim.latestCounterAmount=undefined;
    
                    setAcceptValidated(false);
                    handleAcceptClose();
                } else {
                    setSubmitAcceptStatusState("danger");
                    setSubmitAcceptStatus(data.message);
                }

                setLoading(false);
            }).catch(err =>{
                setSubmitAcceptStatusState("danger");
                setSubmitAcceptStatus("Unknown Error");
                setLoading(false);
            });
        }
    }

    const showAcceptAlert = () => {
        if (submitAcceptStatus && submitAcceptStatus.length) {
            return (
                <div>
                    <Alert variant={submitAcceptStatusState}>
                        {submitAcceptStatus}
                    </Alert>                    
                </div>
            );
        }
    }

    // counter offer  dialog
    const [showCounterDialog, setShowCounterDialog] = useState(false);
    const [counterValidated, setCounterValidated] = useState(false);
    const [counterAmount, setCounterAmount] = useState(false);
    const [submitCounterStatus, setSubmitCounterStatus] = useState("");
    const [submitCounterStatusState, setSubmitCounterStatusState] = useState("danger");
    const showCounterOffer = (evt) => {
        handleCounterShow();
    }
    const handleCounterShow = () => {
        setSubmitCounterStatus(false);
        setCounterValidated(false);
        setAcceptTosChecked(false);
        setCounterAmount(claim.latestCounterAmount);
        setShowCounterDialog(true);
    }
    const handleCounterClose = () => {
        setShowCounterDialog(false);
    }
    const handleCounterSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        console.log("SUBMIT COUNTER: "+counterAmount)
        const form = event.currentTarget;
        let valid = form.checkValidity();
        
        setCounterValidated(true);

        if (!acceptTosChecked) return;

        let url = "/api/secure/approve-counter";
        let body =  {
            claim: claim,
            acceptTos:  acceptTosChecked,
            counterAmount: parseFloat(counterAmount).toFixed(2),
            latestCounterAmount: parseFloat(claim.latestCounterAmount).toFixed(2)
        };
        
        setSubmitCounterStatus(false);
        setLoading(true);
        post(url, {
            payload: body,
            headers: 
                {
                    "Authorization": "Bearer "+GetUser().jwttoken,
                    "Content-Type": "application/json"
                }
        }).then(data => {
            if (data.status===0) {
                claim.status="Counter Pending";
                claim.latestCounterAmount=counterAmount;
                claim.acceptNeedsApproval=false;
                claim.counterNeedsApproval=false;

                setCounterValidated(false);
                handleCounterClose();
            } else {
                setSubmitCounterStatusState("danger");
                setSubmitCounterStatus(data.message);
            }
            
            setLoading(false);
        }).catch(err =>{
            setSubmitCounterStatusState("danger");
            setSubmitCounterStatus("Unknown Error");
            setLoading(false);
        });
    }
    const showCounterAlert = () => {
        if (submitCounterStatus && submitCounterStatus.length) {
            return (
                <div>
                    <Alert variant={submitCounterStatusState}>
                        {submitCounterStatus}
                    </Alert>                    
                </div>
            );
        }
    }

    const outputBucket = (bucket) => {
        if (bucket=='Not Assigned' || bucket=='Not Matched') {
            return (<span style={{color: "red", fontWeight: "bold"}}>{bucket}</span>)
        }
        if (bucket=='Matched to EMR') {
            return (<span style={{color: "green", fontWeight: "bold"}}>{bucket}</span>)
        }
        return (<>{bucket}</>);
    }

    const outputOffer = () => {
        return (<>${parseFloat(claim.offer.amount).toFixed(2)}</>);
    }

    const outputCurrentOffer = () => {
        if (claim.offer.amount>0) {
            let btnClass = "btn ";
            if (claim.acceptNeedsApproval) {
                btnClass += "btn-warning";
            } else if (claim.minimumAccepted && claim.minimumAccepted!='') {
                if (claim.offer.amount>=claim.minimumAccepted) {
                    btnClass += "btn-success";
                } else {
                    btnClass += "btn-danger";
                }
            } 
            if (claim.canAccept) {
                return <button className={btnClass} onClick={showAcceptOffer}>
                    {outputOffer()}
                </button>
            } else {
                return outputOffer();
            }
        } else {
            return <></>;
        }
    }
    const outputCounterOffer = () => {
        if (claim.latestCounterAmount) {
            if (claim.counterNeedsApproval) {
                let btnClass = "btn btn-warning";
                return <button className={btnClass} onClick={showCounterOffer}>
                    ${parseFloat(claim.latestCounterAmount).toFixed(2)}
                </button>
            } else {
                return <>${parseFloat(claim.latestCounterAmount).toFixed(2)}</>
            }
        }
        return <></>
        //claim.acceptNeedsApproval counterNeedsApproval
    }


    const outputMPClaim = () => {
        if (claim.multiplanUrl && claim.multiplanUrl.length) {
            let url = claim.multiplanUrl

            return <>
                <a href={url} target="multiplan" style={{color:"green", whiteSpace: "nowrap"}}>
                    <b>{claim.multiplanClaim}</b> <FontAwesomeIcon icon="square-arrow-up-right" />
                </a>
            </>
        } else {
            return <>{claim.multiplanClaim}</>
        }
    }

    const outputExpiringStatus = () => {
        if (claim.expiringStatus && claim.expiringStatus.length) {
            return  (<div style={{textAlign: "center"}}><FontAwesomeIcon style={{color: "red", fontSize: "28px"}} icon="triangle-exclamation" /></div>)
        }
        return <></>
    }

    const outputExpiringDate = () => {
        if (claim.expiringDate!==null && claim.expiringDate.getTime()>0) {
            return   (<><FormattedDate value={claim.expiringDate} month='numeric' day='numeric'/><br/>
                        <FormattedTime value={claim.expiringDate} hour="numeric" minute="numeric"/></>)
        } else {
            return <></>
        }
    }
    
    const viewOffer = (evt) => {
        const url = "/claim/"+claim.id;
        if (url && url.length) {
            //navigate(url);
            window.open(url, "offerdetails");
        }
        evt.preventDefault();
    };

    const outputStatus = () => {
        return (
            <>
                <a href="#" style={{color: "green", fontWeight: "bold"}}  onClick={(e) => {viewOffer(e)}}>{claim.status}</a>
            </>
        )
    }
    return (
        <>
    
            <tr>
                <td>{outputExpiringStatus()}</td>
                <td>{outputExpiringDate()}</td>
                <td>
                    <div>{claim.payor}</div>
                    <div>{claim.payorClaim}</div>
                    <div>{outputMPClaim()}</div>
                    <div>{claim.multiplanAccount}</div>
                </td>
                <td>{claim.provider}</td>
                <td>
                    <div>{claim.patientName}</div>
                    <div>{claim.dos}</div>
                    <div>{outputBucket(claim.bucketName)}</div>
                </td>
                <td>${claim.billedCharges}</td>
                <td>{claim.minimumAccepted!=''?('$'+claim.minimumAccepted):''}</td>
                <td>{claim.targetOffer!=''?('$'+claim.targetOffer):''}</td>
                <td>{outputCurrentOffer()}</td>
                <td>{outputCounterOffer()}</td>
                <td>{outputStatus()}</td>
            </tr>
            
            <Modal show={showAcceptDialog} onHide={handleAcceptClose} className="repricer-site site-body">
                <Modal.Header closeButton>
                    <Modal.Title><b>Accept Offer</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={acceptValidated} onSubmit={handleAcceptSubmit}>
                        {showAcceptAlert()}

                        <p>Offer is <b>${parseFloat(claim.offer.amount).toFixed(2)}</b> on a billed amount of 
                            <b>${claim.billedCharges}</b> for {claim.patientName} with a service date of {claim.dos}.</p>

                        <div className="float-left">
                            <Form.Check label="I accept MaxRepricer's and MultiPlan's terms of service for accepting offer." required={true} checked={acceptTosChecked} onChange={toggleTosChecked}/>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAcceptClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAcceptSubmit}>
                        Accept Offer
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal show={showCounterDialog} onHide={handleCounterClose} className="repricer-site site-body">
                <Modal.Header closeButton>
                    <Modal.Title><b>Approve Counter Proposal</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={counterValidated} onSubmit={handleCounterSubmit}>
                        {showCounterAlert()}

                        <p>There is a pending counter proposal that needs your approval.</p>

                        <p>The current offer from MultiPlan is <b>${parseFloat(claim.offer.amount).toFixed(2)}</b> on a billed amount of 
                            <b>${claim.billedCharges}</b> for {claim.patientName} with a service date of {claim.dos}.</p>

                        <p>We have determined that this counter offer should be <b>${parseFloat(claim.latestCounterAmount).toFixed(2)}</b>.  You can edit the amount before approving.</p>
                        <Form.Group controlId="form.counterAmount" className="mb-3">
                            <Form.Label className="body-bold">Counter Offer Amount</Form.Label>
                            <Form.Control placeholder="" required  style={{textAlign: "right"}}
                                type="number" step="0.01" min={claim.offer.amount} max={claim.billedCharges} 
                                value={counterAmount} onChange={(e) => setCounterAmount(e.target.value)} />
                            <Form.Control.Feedback type="invalid">Please provide a percentage between {claim.offer.amount} and {claim.billedCharges}.</Form.Control.Feedback>
                        </Form.Group>

                        <div className="float-left">
                            <Form.Check label="I accept MaxRepricer's and MultiPlan's terms of service for making this counter offer." required={true} checked={acceptTosChecked} onChange={toggleTosChecked}/>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCounterClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCounterSubmit}>
                        Counter Offer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ActiveClaim;
